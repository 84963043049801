import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import CryptoJS from "crypto-js";

const NewAppointment = ({ userID }) => {
  const [params, setParams] = useState({ param1: "", param2: "" });
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const [loading, setLoading] = useState(true);
  const [physioID, setPhysioID] = useState("");
  const [pName, setPName] = useState("")
  const [physName,setPhysName] = useState("")

  //On load get the physio id
  const fetchPhysioData = async () => {
    try {
      const userid = userID.dataID;
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athlete/getPhysioPTData?userID=${userid}`
      );
      const result = await response.json();
      console.log('physio belongs:' , result)
      const belongsto = result[0].belongsTo[0];
      setPhysioID(belongsto);
      return(belongsto)
    } catch (error) {
      console.error("Error fetching programming data:", error);
    } finally {
      setLoading(false);
    }
  };

  const decryptField = (encryptedValue, encryptionKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const getUserName = async (userID) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/joinPhysio/getUsers?dataID=${userID}`
      );
      const userData = await response.json();
      const decryptedName = decryptField(userData.name, encryptionKey);
      console.log(decryptedName)
      return decryptedName
    } catch (error) {
      console.log(error);
    }
  };


  const handleBookNow = () => {
    const params = new URLSearchParams({
      param1: pName,
      param2: physName,
      param3: physioID,
      param4: userID.dataID     
    });
    setParams({
      param1: pName,
      param2: physName,
      param3: physioID,
      param4: userID.dataID 
    });

    // Redirect to the booking route
    navigate(`/athlete/booking?${params.toString()}`);
  };

  useEffect(() => {
    const getData = async () => {
      const lphysioID = await fetchPhysioData();
      const name = await getUserName(userID.dataID)
      const phname = await getUserName(lphysioID)
      setPName(name)
      setPhysName(phname)
    };
    getData();
  }, []);

  return (
    <div>
      <h2>Book a new appointment!</h2>
      <button onClick={handleBookNow} style={{backgroundColor: "#000000", color: "#ffffff", fontSize: '16px' }}>Book Now!</button>
    </div>
  );
};

export default NewAppointment;
