import React, { useEffect, useState } from 'react';
import './VideoSelector.css'; // Importing the CSS file for styles
import { FaPlay, FaTimes } from 'react-icons/fa'; // Importing the play and close icons from react-icons

const VideoSelector = ({ userId, onSelectVideo }) => {
  const [videoList, setVideoList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    const fetchVideos = async () => {
      const user = userId.dataID;
      try {
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/videos/listVideos?userId=${user}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch videos');
        }
        const videos = await response.json();
        setVideoList(videos);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching videos:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchVideos();
  }, [userId]);

  const fetchVideoUrl = async (videoName) => {
    const user = userId.dataID;
    try {
      const fileName = `userVideos/${user}+${videoName}`;
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/videos/playVideos?fileName=${encodeURIComponent(fileName)}`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch video URL');
      }
      const { url } = await response.json();
      setVideoUrl(url);
    } catch (error) {
      console.error('Error fetching video URL:', error);
      setError(error.message);
    }
  };

  const handleVideoSelect = (video) => {
    fetchVideoUrl(video);
    onSelectVideo(video);
  };

  const handleCloseVideo = () => {
    console.log("Closing")
    setVideoUrl(''); // Close the video player by resetting the video URL
  };

  if (loading) {
    return <p>Loading videos...</p>;
  }

  if (error) {
    return <p>Error loading videos: {error}</p>;
  }

  return (
    <div className="video-selector">
      <div className="video-list">
        {videoList.map((video, index) => (
          <div key={index} className="video-item">
            <button className="video-button" onClick={() => handleVideoSelect(video)}>
              <div className="thumbnail">
                <FaPlay className="play-icon" style={{color: '#ffffff'}}/>
              </div>
              <span className="video-title" style={{color: '#ffffff', fontSize: '16px'}}>{video}</span>
            </button>
          </div>
        ))}
      </div>
      {videoUrl && (
        <div className="video-player">
          <button className="close-button" onClick={handleCloseVideo}>
            <FaTimes className="close-icon" />
          </button>
          <video controls src={videoUrl} />
        </div>
      )}
    </div>
  );
};

export default VideoSelector;
