import React, { useState } from "react";
import './appSettings.css';

const AppSettings = ({ dataID }) => {
    const [headerColor, setHeaderColor] = useState("#007bff");
    const [backgroundColor, setBackgroundColor] = useState("#fff");
    const [logo, setLogo] = useState(null);
    const [logoUP, setLogoUP] = useState(null)

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogo(URL.createObjectURL(file));
            setLogoUP(file);
        }
    };

    const uploadColours = async () => {
        try {
            const url = `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/branding/uploadColours?dataID=${dataID.dataID}&colourMain=${encodeURIComponent(headerColor)}&colourSecond=${encodeURIComponent(backgroundColor)}`;
            const response = await fetch(url, {
                method: "POST",
            });
            if (!response.ok) {
                throw new Error("Failed to upload colors");
            }
            console.log("Colors uploaded successfully");
        } catch (error) {
            console.error(error);
        }
    };

    const imageUpload = async () => {
        if (!logo) return alert("Please select a logo image");

        try{
            const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/branding/getLogoPSURL?dataID=${dataID.dataID}`)
            const data = await response.json();
            const presignedURL = data.upload_url;

            try {
                await fetch(presignedURL, {
                  method: 'PUT',
                  body: logoUP,
                  headers: {
                    'Content-Type': logoUP.type,
                  },
                });
                alert('Image uploaded successfully');
              } catch (error) {
                console.error('Error uploading image:', error);
              }

        }catch (error) {
            console.log(error);
        }
    }
    

    const handleSubmit = async () => {
        await uploadColours();
        await imageUpload();
        // Later, call the second API here
    };

    return (
        <div className="settingsContainer">
            <div>
                <label>Set Header Text Color: </label>
                <input
                    type="color"
                    value={headerColor}
                    onChange={(e) => setHeaderColor(e.target.value)}
                    className="settingsInput"
                />
            </div>

            <div>
                <label>Set Header Background Color: </label>
                <input
                    type="color"
                    value={backgroundColor}
                    onChange={(e) => setBackgroundColor(e.target.value)}
                    className="settingsInput"
                />
            </div>

            <div>
                <label>Upload Logo: </label>
                <input type="file" accept="image/*" onChange={handleLogoChange} className="settingsInput"/>
                {logo && <img src={logo} alt="Logo Preview" style={{ width: "100px", marginTop: "10px" }} />}
            </div>

            <button onClick={handleSubmit} className="settingsButton">Submit</button>
        </div>
    );
};

export default AppSettings;
