import React, { useState, useEffect } from 'react';
import './header.css';
import LogoutButton from '../../login/logout';
import defaultLogo from '../../images/logoLightnoDigilab.png';

const Header = ({ onLinkClick, tier, dataID }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // State to handle mobile menu open/close
  const [colorMain, setColorMain] = useState("#007bff");
  const [colorSecond, setColorSecond] = useState("#fff");
  const [logoURL, setLogoURL] = useState(defaultLogo);

  useEffect(() => {
    if (tier === "2") {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/branding?dataID=${dataID.dataID}`);
          const data = await response.json();
          const fetchedLogoURL = data.logoURL?.S || defaultLogo;
          const colourMain = data.colourMain?.S || "#007bff";
          const colourSecond = data.colourSecond?.S || "#fff";
          setLogoURL(fetchedLogoURL);
          setColorMain(colourMain);
          setColorSecond(colourSecond);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } else {
      setLogoURL(defaultLogo); // Set to default logo for other tiers
    }
  }, [tier, dataID]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggles the state
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false); // Close the menu when a link is clicked
  };

  const tierLabel = tier === "1" ? "Coach" : tier === "2" ? "Coach Plus" : "Coach";

  return (
    <header className='headerPhysio' style={{backgroundColor: colorSecond}}>
      <div className="logo-placeholderr">
        <img src={logoURL} alt='AthlioConnect Logo' className='logo' />
      </div>
      <h1 className="header-title" style={{color: colorMain}}>{tierLabel}</h1>
      
      {/* Hamburger Icon for mobile view */}
      <div className="hamburger" onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      {/* Standard Nav for larger screens */}
      <nav className="desktop-nav">
        <ul>
          <li><a onClick={() => onLinkClick('home')} style={{ color: colorMain }}>Home</a></li>
          <li><a onClick={() => onLinkClick('training')} style={{ color: colorMain }}>Training</a></li>
          <li><a onClick={() => onLinkClick('about')} style={{ color: colorMain }}>About</a></li>
          <li><a onClick={() => onLinkClick('profile')} style={{ color: colorMain }}>Profile</a></li>
          <li><a href="https://yjabkt-xa.myshopify.com/" target="_blank" rel="noopener noreferrer" style={{ color: colorMain }}>Merch</a></li>
          <li><LogoutButton /></li>
        </ul>
      </nav>

      {/* Mobile flyout menu - toggle with state */}
      <div className={isMobileMenuOpen ? 'mobile-menu mobile-menu-open' : 'mobile-menu'}>
        <ul>
          <li><a onClick={() => { onLinkClick('home'); closeMobileMenu(); }} style={{ color: colorMain }}>Home</a></li>
          <li><a onClick={() => { onLinkClick('training'); closeMobileMenu(); }} style={{ color: colorMain }}>Training</a></li>
          <li><a onClick={() => { onLinkClick('about'); closeMobileMenu(); }} style={{ color: colorMain }}>About</a></li>
          <li><a onClick={() => { onLinkClick('profile'); closeMobileMenu(); }} style={{ color: colorMain }}>Profile</a></li>
          <li><a href="https://yjabkt-xa.myshopify.com/" target="_blank" rel="noopener noreferrer" style={{ color: colorMain }}>Merch</a></li>
          <li><LogoutButton /></li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
