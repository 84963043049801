import React, { useEffect, useState } from "react";
import "./header.css";
import LogoutButton from "../../login/logout";
import defaultLogo from '../../images/logoLightnoDigilab.png';

const Header = ({ onLinkClick, tier, dataID }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [colorMain, setColorMain] = useState("#007bff");
  const [colorSecond, setColorSecond] = useState("#fff");
  const [logoURL, setLogoURL] = useState(defaultLogo);

  useEffect(() => {
    if (tier === "2") {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/branding?dataID=${dataID.dataID}`);
          const data = await response.json();
          const fetchedLogoURL = data.logoURL?.S || defaultLogo;
          const colourMain = data.colourMain?.S || "#007bff";
          const colourSecond = data.colourSecond?.S || "#fff";
          setLogoURL(fetchedLogoURL);
          setColorMain(colourMain);
          setColorSecond(colourSecond);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } else {
      setLogoURL(defaultLogo); // Set to default logo for other tiers
    }
  }, [tier, dataID]);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const tierLabel = tier === "1" ? "Manager" : tier === "2" ? "Manager Plus" : "Manager";

  return (
    <header className="headerPhysio" style={{backgroundColor: colorSecond}}>
      <div className="logo-placeholderr">
        <img src={logoURL} alt="AthlioConnect Logo" className="logo" />
      </div>
      <h1 className="header-title" style={{color: colorMain}}>{tierLabel}</h1>

      <div className="hamburger" onClick={toggleMobileMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <nav className="desktop-nav">
        <ul>
          <li>
            <a onClick={() => onLinkClick("home")} style={{color: colorMain}}>Home</a>
          </li>
          <li>
            <a onClick={() => onLinkClick("training")} style={{color: colorMain}}>Training</a>
          </li>
          <li>
            <a onClick={() => onLinkClick("about")} style={{color: colorMain}}>About</a>
          </li>
          <li>
            <a onClick={() => onLinkClick("profile")} style={{color: colorMain}}>Profile</a>
          </li>

          <li className="dropdown">
            <a className="dropdown-toggle" onClick={toggleDropdown} style={{color: colorMain}}>
              Management
            </a>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <a onClick={() => onLinkClick("practice")} style={{color: colorMain}}>Practice management</a>
                </li>
                <li>
                  <a onClick={() => onLinkClick("licences")} style={{color: colorMain}}>Licence management</a>
                </li>
                {tier === "2" && (
                  <li>
                    <a onClick={() => onLinkClick("appSettings")} style={{color: colorMain}}>App Settings</a>
                  </li>
                )}
              </ul>
            )}
          </li>

          <li>
            <LogoutButton />
          </li>
        </ul>
      </nav>

      <div className={isMobileMenuOpen ? "mobile-menu mobile-menu-open" : "mobile-menu"}>
        <ul>
          <li>
            <a onClick={() => { onLinkClick("home"); closeMobileMenu(); }} style={{color: colorMain}}>Home</a>
          </li>
          <li>
            <a onClick={() => { onLinkClick("training"); closeMobileMenu(); }} style={{color: colorMain}}>Training</a>
          </li>
          <li>
            <a onClick={() => { onLinkClick("about"); closeMobileMenu(); }} style={{color: colorMain}}>About</a>
          </li>
          <li>
            <a onClick={() => { onLinkClick("profile"); closeMobileMenu(); }} style={{color: colorMain}}>Profile</a>
          </li>

          <li>
            <a onClick={toggleDropdown} className="dropdown-toggle" style={{color: colorMain}}>Management</a>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                <li>
                  <a onClick={() => { onLinkClick("practice"); closeMobileMenu(); }} style={{color: colorMain}}>Practice management</a>
                </li>
                <li>
                  <a onClick={() => { onLinkClick("licences"); closeMobileMenu(); }} style={{color: colorMain}}>Licence management</a>
                </li>
                {tier === "2" && (
                  <li>
                    <a onClick={() => { onLinkClick("appSettings"); closeMobileMenu(); }} style={{color: colorMain}}>App Settings</a>
                  </li>
                )}
              </ul>
            )}
          </li>

          <li>
            <LogoutButton />
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
